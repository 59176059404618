import request from '@/utils/request'

export function regions(query) {
  return request({
    url: '/citizen/getregions',
    method: 'get',
    params: query
  })
}
export function districts(query) {
  return request({
    url: `/citizen/getcities/${query.region_id}`,
    method: 'get',
    params: query
  })
}
