export default {
  'Главная': 'Главная',
  'Требование': 'Требование',
  'Обязанности': 'Обязанности',
  'Условия работы': 'Условия работы',
  'Вакансии': 'Вакансии',
  'мы в соцсетях': 'мы в соцсетях',
  'Найти': 'Найти',
  'Введите регион': 'Введите регион',
  'Выберите город или регион': 'Выберите город или регион',
  'Введите должность или название компании': 'Введите должность или название компании',
  'Введите должность': 'Введите должность',
  'У нас есть вакансии для всех': 'У нас есть вакансии для всех',
  'Законодательство в сфере труда': 'Законодательство в сфере труда',
  'Трудовой кодекс Республики Узбекистан': 'Трудовой кодекс Республики Узбекистан',
  'Закон "О занятости населения"': 'Закон "О занятости населения"',
  'Защита трудовых прав': 'Защита трудовых прав',
  'Обратиться в Государственную инспекцию труда': 'Обратиться в Государственную инспекцию труда',
  'Вакансии по рубрикам': 'Вакансии по рубрикам',
  'Вакансии по регионам': 'Вакансии по регионам',
  'вакансии': 'вакансии',
  'компании': 'компании',
  'Министерство занятости и трудовых отношений Республики Узбекистан': 'Министерство занятости и трудовых отношений Республики Узбекистан',
  'Республика Каракалпакстан': 'Республика Каракалпакстан',
  'Андижанская область': 'Андижанская область',
  'Бухарская область': 'Бухарская область',
  'Джизакская область': 'Джизакская область',
  'Кашкадарьинская область': 'Кашкадарьинская область',
  'Навоийская область': 'Навоийская область',
  'Наманганская область': 'Наманганская область',
  'Самаркандская область': 'Самаркандская область',
  'Сырдарьинская область': 'Сырдарьинская область',
  'Сурхандарьинская область': 'Сурхандарьинская область',
  'Ташкентская область': 'Ташкентская область',
  'Ферганская область': 'Ферганская область',
  'Хорезмская область': 'Хорезмская область',
  'Город Ташкент': 'Город Ташкент',
  'IT, Компьютеры, Интернет': 'IT, Компьютеры, Интернет',
  'Бухгалтерия, Аудит': 'Бухгалтерия, Аудит',
  'Дизайн, Фото': 'Дизайн, Фото',
  'Инженерия, Технологии': 'Инженерия, Технологии',
  'Сортировать по': 'Сортировать по',
  'зарплате по возрастанию': 'Зарплате по возрастанию',
  'зарплате по убыванию': 'Зарплате по убыванию',
  'от А до Я': 'от А до Я',
  'от Я до А': 'от Я до А',
  'сум': 'сум',
  'Выберите': 'Выберите',
  'Hudud': 'Hudud',
  'Tuman': 'Tuman',
  'Есть надбавка': 'Есть надбавка',
  'Создать резюме': 'Создать резюме',
  
  'Паспорт': 'Паспорт',
  'ПИНФЛ': 'ПИНФЛ',
  'Адрес': 'Адрес',
  'Год рождение': 'Год рождение',
  'Национальность': 'Национальность',
  'Пол': 'Пол',
  'Партийность': 'Партийность',
  'Образование': 'Образование',
  'ВУЗ': 'ВУЗ',
  'Факултет': 'Факултет',
  'Дата поступление': 'Дата поступление',
  'Дата окончание': 'Дата окончание',
  'Серия диплома': 'Серия диплома',
  'Дата поручение диплома': 'Дата поручение диплома',
  'Опыт работы': 'Опыт работы',
  'Компания': 'Компания',
  'Дольжность': 'Дольжность',
  'Период работы': 'Период работы',
  'Мобилный телефон': 'Мобилный телефон',
  'Домашный телефон': 'Домашный телефон',
  'Электронная почта': 'Электронная почта',
  'Семейное положение': 'Семейное положение',
  'Войенное звание': 'Войенное звание',
  'Haydovchilik guvohnomasi': 'Водительское права',
  'Дополнительная информация': 'Дополнительная информация',
  'О себе': 'О себе',
  'Личное качество': 'Личное качество',
  'Компютерные навыки': 'Компютерные навыки',
  'Хобби': 'Хобби',
  'Сохранить': 'Сохранить',
  'Отменит': 'Отменит',
  'Да': 'Да',
  'Нет': 'Нет',
  'Резуме': 'Резюме',
  'Мужчина': 'Мужчина',
  'Женщина': 'Женщина',
  'Холостяк': 'Холостяк',
  'Женать': 'Женать',
  'Разведёнь': 'Разведёнь',
  'Замужем': 'Замужем',
  'Не замужем': 'Не замужем',
  'Успешно сохранено': 'Успешно сохранено',
  'Невозможно сохранить': 'Невозможно сохранить',
  'Выход': 'Выход',
  'Ощибка': 'Ощибка',
  'Успешно': 'Успешно',
  'Избранное': 'Избранное',
  'Откликнуться': 'Откликнуться',
  'Откликнутые': 'Откликнутые',
  'Успешно добавлено в избранное': 'Успешно добавлено в избранное',
  'Успешно удалено из избранное': 'Успешно удалено из избранное',
  'Изменит фото': 'Изменит фото',
  'Профиль': 'Профиль',
  'Знание языков': 'Знание языков',
  'Добавить язык': 'Добавить язык',
  'Добавить': 'Добавить',
  'ТРУДОВАЯ ДЕЯТЕЛЬНОСТЬ': 'ТРУДОВАЯ ДЕЯТЕЛЬНОСТЬ',
  'Раздел': 'Раздел',
  'ФИО': 'ФИО',
  'Дата заявки': 'Дата заявки',
  'Статус': 'Статус',
  'Операция': 'Операция',
  'Вакансии(вакантные должности)': 'Вакансии(вакантные должности)',
  'Обрашещения': 'Обрашещения',
  'Приглашенные на собеседование': 'Приглашенные на собеседование',
  'Отмененные обращение': 'Отмененные обращение',
  'Приглашенные на работу': 'Приглашенные на работу',
  'Комментария': 'Комментария',
  'Данные отсутствует': 'Данные отсутствует',
  // 
  'Не имеет значения': 'Не имеет значения'
}
