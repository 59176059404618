import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/main'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home.vue')
      },
      {
        path: '/application-leave',
        name: 'ApplicationLeave',
        component: () => import('../views/application/application-leave.vue')
      },
      {
        path: '/application-create',
        name: 'ApplicationCreate',
        component: () => import('../views/application/create.vue')
      },
      {
        path: '/check-status',
        name: 'CheckApplication',
        component: () => import('../views/application/check-status.vue')
      },
      {
        path: '/citizen-message',
        name: 'CitizenMessage',
        component: () => import('../views/message/citizen-service.vue')
      },
      {
        path: '/check-status-message',
        name: 'CheckStatusMessage',
        component: () => import('../views/message/check-status-message.vue')
      },
      {
        path: '/appointment-create',
        name: 'AppointmentCreate',
        component: () => import('../views/appointment/create.vue')
      },
      {
        path: '/mehnat-M-haqida',
        name: 'AboutInfo',
        component: () => import('../views/about.vue')
      }
    ]
  }  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
