export default {
  'Главная': 'Bosh sahifa',
  'enter': 'Kirish',
  'Требование': 'Talablar',
  'Обязанности': 'Majburiyatlar',
  'Условия работы': 'Ish shartlari',
  'Вакансии': 'Vakansiyalar',
  'мы в соцсетях': 'biz ijtimoiy tarmoqlarda',
  'Найти': 'Izlash',
  'Введите регион': 'Hududni kiriting',
  'Выберите город или регион': 'Shahar yoki hududni tanlang',
  'Введите должность или название компании': 'Lavozim yoki tashkilot nomini kiriting',
  'Введите должность': 'Lavozimni kiriting',
  'У нас есть вакансии для всех': 'BIZDA HAMMA UCHUN VAKANSIYALAR BOR',
  'Законодательство в сфере труда': 'MEHNAT SOHASIDAGI QONUNChILIK',
  'Трудовой кодекс Республики Узбекистан': 'O‘zbekiston Respublikasi Mehnat kodeksi',
  'Закон "О занятости населения"': '"Aholi bandligi to‘g‘risida" qonun',

  'Защита трудовых прав': 'MEHNAT HUQUQLARI MUHOFAZASI',
  'Обратиться в Государственную инспекцию труда': 'Davlat mehnat inspeksiyasiga murojaat qilish',
  'Вакансии по рубрикам': 'RUKNLAR BO‘YICHA VAKANSIYALAR',
  'Вакансии по регионам': 'HUDUDLAR BO‘YICHA VAKANSIYALAR',
  'вакансии': 'vakansiyalar',
  'компании': 'tashkilotlar',
  'Министерство занятости и трудовых отношений Республики Узбекистан': 'O\'zbekiston Respublikasi Kambag\'allikni qisqartirish va bandlik vazirligi',
  'Республика Каракалпакстан': 'Qoraqalpog‘iston Respublikasi',
  'Андижанская область': 'Andijon viloyati',
  'Бухарская область': 'Buxoro viloyati',
  'Джизакская область': 'Jizzax viloyati',
  'Кашкадарьинская область': 'Qashqadaryo viloyati',
  'Навоийская область': 'Navoiy viloyati',
  'Наманганская область': 'Namangan viloyati',
  'Самаркандская область': 'Samarqand viloyati',
  'Сырдарьинская область': 'Sirdaryo viloyati',
  'Сурхандарьинская область': 'Surxondaryo viloyati',
  'Ташкентская область': 'Toshkent viloyati',
  'Ферганская область': 'Farg‘ona viloyati',
  'Хорезмская область': 'Xorazm viloyati',
  'Город Ташкент': 'Toshkent shahri',
  'IT, Компьютеры, Интернет': 'IT, Kompyuterlar, Internet',
  'Бухгалтерия, Аудит': 'Buxgalteriya, Audit',
  'Дизайн, Фото': 'Dizayn, Foto',
  'Инженерия, Технологии': 'Muhandislik, Texnologiyalar',
  'Сортировать по': 'Saralash',
  'зарплате по возрастанию': 'Oshib boruvchi ish haqi',
  'зарплате по убыванию': 'Kamayib boruvchi ish haqi',
  'от А до Я': 'А dan Ya gacha',
  'от Я до А': 'Ya dan А gacha',
  'сум': "so'm",
  'Выберите': 'Tanlang',
  'Hudud': 'Hudud',
  'Tuman': 'Tuman',
  'Есть надбавка': "Qo'shimcha haq mavjud",
  'Создать резюме': 'Rezyume yaratish',

  'Паспорт': 'Pasport',
  'ПИНФЛ': 'JShShIR',
  'Адрес': 'Manzil',
  'Год рождение': "Tug'ilgan sana",
  'Национальность': 'Миллати',
  'Пол': 'Жинси',
  'Партийность': 'Партиявийлиги',
  'Образование': 'Маълумоти',
  'ВУЗ': 'ОТМ',
  'Факултет': 'Мутахассислик',
  'Дата поступление': 'Кирган йили',
  'Дата окончание': 'Тамомлаган йили',
  'Серия диплома': 'Диплом серияси ва раками',
  'Дата поручение диплома': 'Диплом берилган сана',
  'Опыт работы': 'Иш малакаси',
  'Компания': 'Корхона',
  'Дольжность': 'Лавозим',
  'Период работы': 'Фаолият даври',
  'Мобилный телефон': 'Мобил телефон',
  'Домашный телефон': 'Уй телефони',
  'Электронная почта': 'Электрон манзили',
  'Семейное положение': 'Оилавий ҳолат',
  'Войенное звание': 'Ҳарбий унвони',
  'Haydovchilik guvohnomasi': 'Haydovchilik guvohnomasi',
  'Дополнительная информация': 'Қўшимча маълумот',
  'О себе': 'Ўзим ҳақимда',
  'Личное качество': 'Шахсий салоҳият',
  'Компютерные навыки': 'Компьютер кўникмаси',
  'Хобби': 'Хобби',
  'Сохранить': 'Сақлаш',
  'Отменит': 'Бекор қилиш',
  'Да': 'Ха',
  'Нет': 'йўқ',
  'Резуме': 'Rezume',
  'Мужчина': 'Erkak',
  'Женщина': 'Ayol',
  'Холостяк': 'O\'zbek',
  'Женать': 'Uylangan',
  'Разведёнь': 'Ajrashgan',
  'Замужем': 'Turmushga chiqqan',
  'Не замужем': 'Turmushga chiqmagan',
  'Успешно сохранено': 'Muvaffaqiyatli saqlandi',
  'Невозможно сохранить': 'Saqlab bo\'lmaydi',
  'Успешно': 'Muvaffaqiyatli',
  'Избранное': 'Saralanganlar',
  'Откликнуться': 'Javob berish',
  'Откликнутые': 'Javob berilganlar',
  'Успешно добавлено в избранное': 'Saralanganlarga muvaffaqiyatli qo\'shildi',
  'Успешно удалено из избранное': 'Saralanganlarga muvaffaqiyatli o\'chirildi',
  'Изменит фото': 'Rasmni o\'zgartirish',
  'Профиль': 'Profil',
  'Знание языков': 'Tillarni bilishi',
  'Добавить язык': 'Til qo\'shish',
  'Добавить': 'Qo\'shish',
  'ТРУДОВАЯ ДЕЯТЕЛЬНОСТЬ': 'MEHNAT FAOLIYATI',
  'Раздел': 'Bo\'lim',
  'ФИО': 'FISH',
  'Дата заявки': 'Murojat sanasi',
  'Статус': 'Status',
  'Операция': 'Amal',
  'Вакансии(вакантные должности)': 'Vakansiyalar(vakant lavozimlar)',
  'Обрашещения': 'Murojatlar',
  'Приглашенные на собеседование': 'Suhbatga chaqirilganlar',
  'Отмененные обращение': 'Bekor qilinganlar',
  'Приглашенные на работу': 'Ishga taklif qilinganlar',
  'Комментария': 'Izoh',
  'Данные отсутствует': 'Ma\'lumot mavjud emas',
  //
  'Не имеет значения': 'Ahamiyatsiz'

}
