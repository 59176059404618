import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import global from './mixins/global'
import Maska from 'maska'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/style/css/style.css'
import './assets/style/css/responsive.css'
import './assets/style/css/main.css'
import './assets/style/scss/main.scss'
import './assets/style/scss/bootstrap/bootstrap.min.js'
import installElementPlus from './plugins/element'

const app = createApp(App)
installElementPlus(app)
app
  .use(ElementPlus)
  .use(store)
  .use(router)
  .use(Maska)
  .mixin(global)
  .mount('#app')
