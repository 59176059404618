
import { getDepartments, getServices, getAdmission, getCompanyInfo, checkPassportAdmission } from '@/api/appointment'
export const actions = {
  store({ commit }, data) {
    return new Promise((resolve, reject) => {
      getAdmission(data).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  checkPassportAdmission({ commit }, data) {
    return new Promise((resolve, reject) => {
      checkPassportAdmission(data).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getServices({ commit }, query) {
    return new Promise((resolve, reject) => {
      getServices(query).then(res => {
        commit('SET_SERVICES', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getDepartments({ commit }, query) {
    return new Promise((resolve, reject) => {
      getDepartments(query).then(res => {
        commit('SET_DEPARTMENTS', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getCompanyInfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      getCompanyInfo(data).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
