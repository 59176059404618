export default {
  'Главная': 'Бош саҳифа',
  'Требование': 'Талаблар',
  'Обязанности': 'Мажбуриятлар',
  'Условия работы': 'Иш шартлари',
  'Вакансии': 'Вакансиялар',
  'мы в соцсетях': 'биз ижтимоий тармоқларда',
  'Найти': 'Излаш',
  'Введите регион': 'Ҳудудни киритинг',
  'Выберите город или регион': 'Шаҳар ёки ҳудудни танланг',
  'Введите должность или название компании': 'Лавозим ёки ташкилот номини киритинг',
  'Введите должность': 'Лавозимни киритинг',
  'У нас есть вакансии для всех': 'БИЗДА ҲАММА УЧУН ВАКАНСИЯЛАР БОР',
  'Законодательство в сфере труда': 'МЕҲНАТ СОҲАСИДАГИ ҚОНУНЧИЛИК',
  'Трудовой кодекс Республики Узбекистан': 'Ўзбекистон Республикаси Меҳнат кодекси',
  'Закон "О занятости населения"': '"Аҳоли бандлиги тўғрисида" қонун',

  'Защита трудовых прав': 'МЕҲНАТ ҲУҚУҚЛАРИ МУҲОФАЗАСИ',
  'Обратиться в Государственную инспекцию труда': 'Давлат меҳнат инспекциясига мурожаат қилиш',
  'Вакансии по рубрикам': 'РУКНЛАР БЎЙИЧА ВАКАНСИЯЛАР',
  'Вакансии по регионам': 'ҲУДУДЛАР БЎЙИЧА ВАКАНСИЯЛАР',
  'вакансии': 'вакансиялар',
  'компании': 'ташкилотлар',
  'Министерство занятости и трудовых отношений Республики Узбекистан': 'Ўзбекистон Республикаси Бандлик ва меҳнат муносабатлари вазирлиги',
  'Республика Каракалпакстан': 'Қорақалпоғистон Республикаси',
  'Андижанская область': 'Андижон вилояти',
  'Бухарская область': 'Бухоро вилояти',
  'Джизакская область': 'Жиззах вилояти',
  'Кашкадарьинская область': 'Қашқадарё вилояти',
  'Навоийская область': 'Навоий вилояти',
  'Наманганская область': 'Наманган вилояти',
  'Самаркандская область': 'Самарқанд вилояти',
  'Сырдарьинская область': 'Сирдарё вилояти',
  'Сурхандарьинская область': 'Сурхондарё вилояти',
  'Ташкентская область': 'Тошкент вилояти',
  'Ферганская область': 'Фарғона вилояти',
  'Хорезмская область': 'Хоразм вилояти',
  'Город Ташкент': 'Тошкент шаҳри',
  'IT, Компьютеры, Интернет': 'IT, Компьютерлар, Интернет',
  'Бухгалтерия, Аудит': 'Бухгалтерия, Аудит',
  'Дизайн, Фото': 'Дизайн, Фото',
  'Инженерия, Технологии': 'Муҳандислик, Технологиялар',
  'Сортировать по': 'Саралаш',
  'зарплате по возрастанию': 'Ошиб борувчи иш ҳақи',
  'зарплате по убыванию': 'Камайиб борувчи иш ҳақи',
  'от А до Я': 'А дан Я гача',
  'от Я до А': 'Я дан А гача',
  'сум': 'сўм',
  'Выберите': 'Танланг',
  'Hudud': 'Hudud',
  'Tuman': 'Tuman',
  'Есть надбавка': 'Қўшимча ҳақ мавжуд',
  'Создать резюме': 'Резюме яратиш',

  'Паспорт': 'Паспорт',
  'ПИНФЛ': 'ЖШШИР',
  'Адрес': 'Манзил',
  'Год рождение': 'Туғилган сана',
  'Национальность': 'Миллати',
  'Пол': 'Жинси',
  'Партийность': 'Партиявийлиги',
  'Образование': 'Маълумоти',
  'ВУЗ': 'ОТМ',
  'Факултет': 'Мутахассислик',
  'Дата поступление': 'Кирган йили',
  'Дата окончание': 'Тамомлаган йили',
  'Серия диплома': 'Диплом серияси ва раками',
  'Дата поручение диплома': 'Диплом берилган сана',
  'Опыт работы': 'Иш малакаси',
  'Компания': 'Корхона',
  'Дольжность': 'Лавозим',
  'Период работы': 'Фаолият даври',
  'Мобилный телефон': 'Мобил телефон',
  'Домашный телефон': 'Уй телефони',
  'Электронная почта': 'Электрон манзили',
  'Семейное положение': 'Оилавий ҳолат',
  'Войенное звание': 'Ҳарбий унвони',
  'Haydovchilik guvohnomasi': 'Haydovchilik guvohnomasi',
  'Дополнительная информация': 'Қўшимча маълумот',
  'О себе': 'Ўзим ҳақимда',
  'Личное качество': 'Шахсий салоҳият',
  'Компютерные навыки': 'Компьютер кўникмаси',
  'Хобби': 'Хобби',
  'Сохранить': 'Сақлаш',
  'Отменит': 'Бекор қилиш',
  'Да': 'Ха',
  'Нет': 'йўқ',
  'Резуме': 'Резуме',
  'Мужчина': 'Эркак',
  'Женщина': 'Аёл',
  'Холостяк': 'Ўзбек',
  'Женать': 'Уйланган',
  'Разведёнь': 'Ажрашган',
  'Замужем': 'Турмушга чиқган',
  'Не замужем': 'Турмушга чиқмаган',
  'Успешно сохранено': 'Муваффақиятли сақланди',
  'Невозможно сохранить': 'Сақлаб бўлмайди',
  'Успешно': 'Муваффақиятли',
  'Избранное': 'Сараланганлар',
  'Откликнуться': 'Жавоб бериш',
  'Откликнутые': 'Жавоб берилганлар',
  'Успешно добавлено в избранное': 'Сараланганларга муваффакиятли қўшилди',
  'Успешно удалено из избранное': 'Сараланганларга муваффакиятли ўчирилди',
  'Изменит фото': 'Расмни ўзгартириш',
  'Профиль': 'Профил',
  'Знание языков': 'Тилларни билиши',
  'Добавить язык': 'Тил қўшиш',
  'Добавить': 'Қўшиш',
  'ТРУДОВАЯ ДЕЯТЕЛЬНОСТЬ': 'МЕҲНАТ ФАОЛИЯТИ',
  'Раздел': 'Бўлим',
  'ФИО': 'ФИШ',
  'Дата заявки': 'Мурожат санаси',
  'Статус': 'Статус',
  'Операция': 'Амал',
  'Вакансии(вакантные должности)': 'Вакансиялар(вакант лавозимлар)',
  'Обрашещения': 'Мурожатлар',
  'Приглашенные на собеседование': 'Сухбатга чақирилганлар',
  'Отмененные обращение': 'Бекор қилинганлар',
  'Приглашенные на работу': 'Ишга таклиф қилинганлар',
  'Комментария': 'Изоҳ',
  'Данные отсутствует': 'Малъумот мавжуд эмас',
  'Не имеет значения': 'Аҳамиятсиз'

}
