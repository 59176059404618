import request from '@/utils/request'

export function getServices(query) {
  return request({
    url: '/getServices',
    method: 'get',
    params: query
  })
}
export function getDepartments(query) {
  return request({
    url: '/getDepartments',
    method: 'get',
    params: query
  })
}
export function getAdmission(data) {
  return request({
    url: '/getAdmission',
    method: 'post',
    data
  })
}
export function checkPassportAdmission(data) {
  return request({
    url: '/appeals/check-passport-only-fullname',
    method: 'post',
    data
  })
}
export function getCompanyInfo(query) {
  return request({
    url: '/getCompanyInfo/' + query.inn,
    method: 'get'
  })
}
