import ElementPlus from 'element-plus'
import { createI18n } from 'vue-i18n'
import { getItem } from '@/utils/storage'
import uz_ln from '../locales/element-plus/uz-ln.ts'
import translates from '../locales/index'

const i18n = createI18n({
  locale: getItem('locale') ? getItem('locale') : 'uz_ln',
  fallbackLocale: 'uz_ln',
  messages: { translates }
})
export default (app) => {
  app.use(ElementPlus, { locale: uz_ln })
  app.use(i18n)
}
