import { createStore } from 'vuex'
import region from './region'
import app from './app'
import auth from './auth'
import appointment from './appointment'

export default createStore({
  modules: {
    app,
    auth,
    appointment,
    region
  }
})
